.change {
  display: inline-block;
  animation: bounce 0.5s;
}

@keyframes bounce {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.9);
  }

  0% {
    opacity: 1;
    transform: scale(1);
  }
}
