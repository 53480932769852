@import 'abstracts/variables';
@import 'abstracts/helpers';

.arrow-link {
  display: inline-flex;
  align-items: center;
  font-size: @font-size-large;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.bold {
    font-weight: 600;
    color: @primary-dark;

    &:hover {
      color: @primary-darker;
    }
  }
}
